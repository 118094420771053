import { Countries } from './countriesList';
import { CurrencyType } from '../types';

export const BASE_CURRENCY: CurrencyType = 'USD';

export enum COMPENSATION_TYPE {
  HOURLY = 'hourly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const DEFAULT_MAX_COMPENSATION = {
  YEARLY: 1200000,
  MONTHLY: 100000,
  HOURLY: 10000,
};

export const COUNTRY_SPECIFIC_MAX_COMPENSATION: Partial<
  Record<Countries, { YEARLY: number; MONTHLY: number; HOURLY: number }>
> = {
  [Countries.India]: {
    YEARLY: 10000000,
    MONTHLY: 1000000,
    HOURLY: 10000,
  },
};

export const COUNTRIES_WITH_YEARLY_COMPENSATION = [
  Countries.India,
  Countries.UnitedStates,
];

export const COUNTRIES_WITH_CUSTOM_CURRENCY_INPUT: Partial<
  Record<Countries, { symbol: string; currency: CurrencyType }>
> = {
  [Countries.India]: {
    symbol: '₹',
    currency: 'INR',
  },
};

export const COMPENSATION_OPTIONS = [
  { type: COMPENSATION_TYPE.HOURLY, label: 'Hourly' },
  { type: COMPENSATION_TYPE.MONTHLY, label: 'Monthly' },
  { type: COMPENSATION_TYPE.YEARLY, label: 'Yearly' },
];

export const getRatioForCountry = (country: string | null): number => {
  switch (country) {
    case 'Argentina':
    case 'Brazil':
    case 'Colombia':
    case 'Guatemala':
    case 'Mexico':
    case 'USA':
      return 155.33;
    case 'USA':
      return 153.33;
    case 'India':
      return 149.33;
    case 'Bulgaria':
    case 'Romania':
    case 'Slovakia':
    case 'Slovenia':
    case 'Spain':
      return 152;
    case 'Portugal':
    case 'Poland':
      return 164.67;
    case 'Ukraine':
      return 166;
    default:
    case undefined:
      return 155.33;
  }
};
