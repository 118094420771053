import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { videoInterviewDataStoreKey } from './videoInterview.const';
import {
  setVideoInterviewReminderDate,
  startVideoInterview,
  skipVideoInterview,
  finishVideoInterviewQuestion,
  finishVideoInterview,
  restartVideoInterview,
  viewUserVideoInterview,
  getAvailableVideoInterview,
} from './videoInterview.thunks';
import { API_STATUS } from '../../constants';
import { MatchingVIPosition } from 'src/types/videoInterviewData';
import { IViewUserVideoInterviewData } from 'src/types/userVideoInterviewData';

export interface IVIDataSliceState {
  availabeVideoInterview: {
    apiStatus: API_STATUS;
    data: MatchingVIPosition[] | null;
  };
  userVideoInterviewView: {
    apiStatus: API_STATUS;
    data: IViewUserVideoInterviewData | null;
  };
  isPerformingAction: boolean;
}

const initialState: IVIDataSliceState = {
  availabeVideoInterview: {
    apiStatus: API_STATUS.IDLE,
    data: null,
  },
  userVideoInterviewView: {
    apiStatus: API_STATUS.IDLE,
    data: null,
  },

  isPerformingAction: false,
};

export const videoInterviewSlice = createSlice({
  name: videoInterviewDataStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get available video interviews
      .addCase(getAvailableVideoInterview.pending, (state) => {
        state.availabeVideoInterview.apiStatus = API_STATUS.LOADING;
      })
      .addCase(getAvailableVideoInterview.fulfilled, (state, action) => {
        state.availabeVideoInterview.apiStatus = API_STATUS.COMPLETE;
        state.availabeVideoInterview.data = action.payload;
      })
      .addCase(getAvailableVideoInterview.rejected, (state) => {
        state.availabeVideoInterview.apiStatus = API_STATUS.FAILED;
      })
      // view user video interview
      .addCase(viewUserVideoInterview.pending, (state) => {
        state.userVideoInterviewView.apiStatus = API_STATUS.LOADING;
      })
      .addCase(viewUserVideoInterview.fulfilled, (state, action) => {
        state.userVideoInterviewView.apiStatus = API_STATUS.COMPLETE;
        state.userVideoInterviewView.data = action.payload;
      })
      .addCase(viewUserVideoInterview.rejected, (state) => {
        state.userVideoInterviewView.apiStatus = API_STATUS.FAILED;
      })

      .addMatcher(
        isAnyOf(
          setVideoInterviewReminderDate.pending,
          startVideoInterview.pending,
          skipVideoInterview.pending,
          finishVideoInterviewQuestion.pending,
          finishVideoInterview.pending,
          restartVideoInterview.pending,
        ),
        (state) => {
          state.isPerformingAction = true;
        },
      )
      .addMatcher(
        isAnyOf(
          setVideoInterviewReminderDate.fulfilled,
          startVideoInterview.fulfilled,
          skipVideoInterview.fulfilled,
          finishVideoInterviewQuestion.fulfilled,
          finishVideoInterview.fulfilled,
          restartVideoInterview.fulfilled,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      )
      .addMatcher(
        isAnyOf(
          setVideoInterviewReminderDate.rejected,
          startVideoInterview.rejected,
          skipVideoInterview.rejected,
          finishVideoInterviewQuestion.rejected,
          finishVideoInterview.rejected,
          restartVideoInterview.rejected,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      );
  },
});
