import { MatchingVIPosition } from 'src/types/videoInterviewData';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface IProps {
  availableVideoInterview: MatchingVIPosition[] | null;
  handleSelectVideoInterview: (value: MatchingVIPosition) => void;
  selectedVIPosition: string | null;
}

import { RadioButton } from '../../../../components/radioButton/RadioButton';
import { SelectableWrapper } from '../../../../components/selectableWrapper/SelectableWrapper';
import {
  Box,
  FormControlLabel,
  Link,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
const STEP_MAX_WIDTH = '35rem';

export const VideoInterviewSelectableList = ({
  availableVideoInterview,
  handleSelectVideoInterview,
  selectedVIPosition,
}: IProps) => {
  return (
    <>
      <Typography variant="caption" color="secondary" mt={2} mb={2}>
        Please select a position you'd prefer
      </Typography>
      <RadioGroup value={selectedVIPosition} sx={{ mb: 1, mt: 1, ml: 0 }}>
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          width="100%"
          sx={(theme) => ({
            [theme.breakpoints.up('xl')]: {
              width: STEP_MAX_WIDTH,
            },
          })}
        >
          {availableVideoInterview?.map((vi) => (
            <Box
              key={vi.id}
              sx={(theme) => ({
                margin: '0 0 0.5rem 0',
                maxWidth: STEP_MAX_WIDTH,
                [theme.breakpoints.down('xl')]: {
                  margin: '0 0 0.5rem 0',
                },
              })}
            >
              <SelectableWrapper
                value={vi.id}
                selectedValue={selectedVIPosition}
                onClick={() => handleSelectVideoInterview(vi)}
                display="block"
              >
                <Stack
                  direction="row"
                  justifyContent={'space-between'}
                  alignItems={'start'}
                >
                  <Stack direction="column">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        value={vi.id}
                        control={<RadioButton />}
                        sx={{
                          width: '100%',
                        }}
                        label={
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="body1">
                              {vi.positionName}
                            </Typography>
                          </Box>
                        }
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="start"
                      spacing={1}
                      ml={4}
                    >
                      <Typography color="secondary" variant="caption">
                        {vi.numberOfQuestions} questions,
                      </Typography>
                      <Typography color="secondary" variant="caption">
                        {vi.viDuration} minutes
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="column" alignItems="end">
                    <Tooltip
                      title="View job description"
                      placement="right-start"
                    >
                      <Link
                        target="_blank"
                        variant="body2"
                        whiteSpace="nowrap"
                        href={vi.joLink}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <OpenInNewIcon fontSize="small" color="secondary" />
                      </Link>
                    </Tooltip>
                  </Stack>
                </Stack>
              </SelectableWrapper>
            </Box>
          ))}
        </Box>
      </RadioGroup>
    </>
  );
};
