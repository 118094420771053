import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux';
import {
  useDebounce,
  useFocusInputAfterAnimation,
  useIsLargeView,
  usePersistUserAnswers,
} from '../../hooks';

import { Box, List, ListItem, TextField, Typography } from '@mui/material';
import {
  GoToNextStep,
  PageTransitionWrapper,
  StickyPanel,
} from '../../components';
import GoToPreviousStep from '../../components/goToPreviousStep/GoToPreviousStep';
import { NumberFormatCustom } from './components/NumberFormatCustom';

import { updateCompensation, userSelectors } from '../../redux/user';
import {
  gamifiedResponseSelectors,
  gamifiedResponseSlice,
} from '../../redux/gamifiedResponse';

import { getUserPrefferedName } from '../../utils';
import {
  COMPENSATION_OPTIONS,
  COMPENSATION_TYPE,
  Countries,
  COUNTRIES_WITH_CUSTOM_CURRENCY_INPUT,
  COUNTRIES_WITH_YEARLY_COMPENSATION,
  COUNTRY_SPECIFIC_MAX_COMPENSATION,
  DEFAULT_MAX_COMPENSATION,
  getRatioForCountry,
} from '../../constants';
import { ICompensationBody } from '../../types';
import { useRates } from '../../hooks/useRates';
import { CompensationRate } from './components/CompensationRate';
import { logEvent } from 'src/services';
import isNumber from 'lodash/isNumber';

const convertToConversationType = (
  compensation: number | null,
  country: string | null,
  from: COMPENSATION_TYPE,
  to: COMPENSATION_TYPE,
) => {
  if (!compensation) {
    return 0;
  }

  if (!compensation || from === to) {
    return compensation;
  }
  const ratio = getRatioForCountry(country);

  if (from === COMPENSATION_TYPE.HOURLY) {
    compensation *= ratio;
    if (to === COMPENSATION_TYPE.YEARLY) {
      compensation *= 12;
    }
  }
  if (from === COMPENSATION_TYPE.MONTHLY) {
    if (to === COMPENSATION_TYPE.YEARLY) {
      compensation *= 12;
    } else {
      compensation /= ratio;
    }
  }
  if (from === COMPENSATION_TYPE.YEARLY) {
    compensation /= 12;
    if (to === COMPENSATION_TYPE.HOURLY) {
      compensation /= ratio;
    }
  }

  return compensation > 1 ? Math.round(compensation) : +compensation.toFixed(2);
};

const MIN_WIDTH_BY_TYPE: any = {
  [COMPENSATION_TYPE.HOURLY]: '141px',
  [COMPENSATION_TYPE.MONTHLY]: '181px',
  [COMPENSATION_TYPE.YEARLY]: '200px',
};

const MAX_WIDTH_BY_TYPE: any = {
  [COMPENSATION_TYPE.HOURLY]: 3.5,
  [COMPENSATION_TYPE.MONTHLY]: 3.2,
  [COMPENSATION_TYPE.YEARLY]: 3,
};

const Compensation: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLargeView = useIsLargeView();

  const userData = useAppSelector(userSelectors.getUserData)!;
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [savedCompensation, setSavedCompensation] =
    usePersistUserAnswers<ICompensationBody>('compensationStep');
  useFocusInputAfterAnimation(inputRef);

  const [convertedRates, setConvertedRates] = useState(true);

  const {
    isShowRates,
    userCurrency,
    userCurrencyRate,
    toUserCurrency,
    fromUserCurrencyToBase,
  } = useRates();

  const customCurrencyInput = useMemo(() => {
    return (
      COUNTRIES_WITH_CUSTOM_CURRENCY_INPUT[userData?.country as Countries] ||
      null
    );
  }, [userData?.country]);

  const [compensation, setCompensation] = useState<ICompensationBody>({
    compensationExpectations: null,
    compensationType: COMPENSATION_TYPE.HOURLY,
  });
  const [focusedType, setFocusedType] = useState('');

  const compensationOptions = useMemo(() => {
    const shouldReverse =
      userData?.country &&
      COUNTRIES_WITH_YEARLY_COMPENSATION.includes(
        userData?.country as Countries,
      );
    return shouldReverse
      ? [...COMPENSATION_OPTIONS].reverse()
      : COMPENSATION_OPTIONS;
  }, [userData?.country]);

  useEffect(() => {
    setCompensation({
      compensationExpectations: (() => {
        if (savedCompensation?.compensationExpectations) {
          return savedCompensation.compensationExpectations;
        }
        if (customCurrencyInput) {
          return Number(
            toUserCurrency(userData?.compensationExpectations || 0).replace(
              /,/g,
              '',
            ),
          );
        }
        return userData?.compensationExpectations;
      })(),
      compensationType:
        savedCompensation?.compensationType || userData?.compensationType,
    });
  }, [savedCompensation, userData]);

  useEffect(() => {
    if (isNumber(userData?.compensationExpectations)) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else {
      if (!lastGamifiedMessage) {
        dispatch(
          gamifiedResponseSlice.actions.updateMessage(
            `Welcome back, ${getUserPrefferedName(userData)}!`,
          ),
        );
      }
    }

    logEvent('launchpod-compensation-page-loaded');
  }, []);

  const getMaxCompensationValue = (
    compensationType: COMPENSATION_TYPE,
  ): number | null => {
    const countrySpecificCompensation =
      COUNTRY_SPECIFIC_MAX_COMPENSATION[userData?.country as Countries];
    let maxCompensation = null;
    switch (compensationType) {
      case COMPENSATION_TYPE.HOURLY:
        maxCompensation =
          countrySpecificCompensation?.HOURLY ||
          DEFAULT_MAX_COMPENSATION.HOURLY;
        break;
      case COMPENSATION_TYPE.MONTHLY:
        maxCompensation =
          countrySpecificCompensation?.MONTHLY ||
          DEFAULT_MAX_COMPENSATION.MONTHLY;
        break;
      case COMPENSATION_TYPE.YEARLY:
        maxCompensation =
          countrySpecificCompensation?.YEARLY ||
          DEFAULT_MAX_COMPENSATION.YEARLY;
        break;
      default:
        break;
    }
    return maxCompensation;
  };

  const debouncedRatesCalculation = useDebounce(() => {
    setConvertedRates(true);
  }, 500);

  const handleChangeOnAmount = (amount: string, type: COMPENSATION_TYPE) => {
    if (type !== focusedType) return;

    const newValue = !amount ? null : parseFloat(amount);

    setCompensation({
      ...compensation,
      compensationType: type,
      compensationExpectations: newValue,
    });

    setSavedCompensation({
      ...savedCompensation,
      compensationType: type,
      compensationExpectations: newValue,
    });

    setConvertedRates(false);
    debouncedRatesCalculation();
  };

  const handleSubmit = (e: React.SyntheticEvent | null): void => {
    if (e) {
      e.preventDefault();
    }

    const compensationExpectationsInUsd = customCurrencyInput
      ? Number(
          fromUserCurrencyToBase(
            Number(compensation.compensationExpectations),
            customCurrencyInput.currency,
          ),
        )
      : compensation.compensationExpectations;

    logEvent('launchpod-compensation-continue-click', {
      'Compensation Selected': compensationExpectationsInUsd,
    });

    dispatch(gamifiedResponseSlice.actions.updateMessage('Noted 👌'));

    setTimeout(() => {
      dispatch(
        updateCompensation({
          compensationExpectations: compensationExpectationsInUsd,
          compensationType: compensation.compensationType,
        }),
      );
    }, 500);
  };

  return (
    <PageTransitionWrapper>
      <Box display="flex" data-testid="compensation-layout">
        <GoToPreviousStep />
        <Typography variant="h2">
          What are your{' '}
          <Box
            component="br"
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            })}
          />
          compensation expectations?
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            mt: 2,
            mb: 6,
            [theme.breakpoints.down('lg')]: {
              flexDirection: 'column',
              alignItems: 'start',
              gap: 0,
            },
          })}
        >
          {compensationOptions.map((option, idx) => {
            const value = convertToConversationType(
              compensation.compensationExpectations,
              userData?.country,
              compensation.compensationType,
              option.type,
            );
            return (
              <Fragment key={option.type}>
                <Box
                  sx={{
                    minWidth: MIN_WIDTH_BY_TYPE[option.type],
                    maxWidth: `${
                      value.toString().length * MAX_WIDTH_BY_TYPE[option.type]
                    }ch`,
                    position: 'relative',
                  }}
                >
                  <Typography
                    variant="body1"
                    color={
                      focusedType === option.type ? 'text.purple' : 'text.brand'
                    }
                    mb={1}
                    data-testid={`compensation-${option.type}-label`}
                  >
                    {option.label}
                  </Typography>
                  <TextField
                    inputRef={idx === 0 ? inputRef : undefined}
                    variant="outlined"
                    hiddenLabel
                    value={value || ''}
                    onChange={(e) =>
                      handleChangeOnAmount(e.target.value, option.type)
                    }
                    InputProps={{
                      startAdornment: (
                        <Typography
                          color="text.secondary"
                          fontSize="2rem"
                          margin="0 0.5rem"
                        >
                          {customCurrencyInput?.symbol || '$'}
                        </Typography>
                      ),
                      sx: { fontSize: '2rem' },
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{
                      maxValue: getMaxCompensationValue(option.type),
                      decimalScale: 2,
                      allowNegative: false,
                      allowLeadingZeros: false,
                      inputMode: 'numeric',
                      'data-testid': `compensation-${option.type}-value`,
                    }}
                    required
                    onFocus={() => setFocusedType(option.type)}
                    onBlur={() => setFocusedType('')}
                    sx={(theme) => ({
                      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${theme.palette.text.purple} !important`,
                      },
                    })}
                  />
                  {isShowRates && convertedRates && !customCurrencyInput && (
                    <CompensationRate
                      amount={toUserCurrency(value)}
                      rate={userCurrencyRate}
                      currency={userCurrency}
                      isShowRates={Boolean(value)}
                      isShowExchangeRate={
                        option.type === COMPENSATION_TYPE.HOURLY
                      }
                      sx={{
                        position: 'absolute',
                        width: '100%',
                      }}
                    />
                  )}
                </Box>

                {idx !== COMPENSATION_OPTIONS.length - 1 && (
                  <Box mt={3}>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      display="inline-block"
                    >
                      {!isLargeView && '='}
                    </Typography>
                  </Box>
                )}
              </Fragment>
            );
          })}
        </Box>
      </form>

      <Box
        sx={(theme) => ({
          background: theme.palette.highlight.neutral,
          padding: '4px 40px 8px 16px',
          borderRadius: '4px',
          width: 'fit-content',
        })}
      >
        <List
          sx={(theme) => ({
            color: theme.palette.secondary.main,
          })}
        >
          {!customCurrencyInput && (
            <ListItem sx={{ marginBottom: '0 !important' }}>
              <Typography variant="body1" color="text.primary">
                Compensation amounts in USD
              </Typography>
            </ListItem>
          )}
          <ListItem sx={{ marginBottom: '0 !important' }}>
            <Typography variant="body1" color="text.primary">
              Payments in local currency, monthly
            </Typography>
          </ListItem>
          <ListItem sx={{ marginBottom: '0 !important' }}>
            <Typography variant="body1" color="text.primary">
              Gross, before taxes
            </Typography>
          </ListItem>
        </List>
      </Box>
      <StickyPanel>
        <GoToNextStep
          isLoading={isLoading}
          isDisabled={!compensation.compensationExpectations}
          textIdentifier={0}
          handleSubmitStep={() => handleSubmit(null)}
          maxWidth={{ xs: '10rem', sm: 'initial' }}
        />
      </StickyPanel>
    </PageTransitionWrapper>
  );
};

export default Compensation;
