import { BASE_CURRENCY, Countries } from '../constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CurrencyType } from '../types';
import { useAppDispatch, useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { convertCurrencyTo } from '../utils/convertCurrencyTo';
import { formatToNumber } from '../utils/formatToNumber';
import {
  exchangeRatesSelectors,
  fetchExchangeRates,
} from '../redux/exchangeRates';
import { useLPLocation } from './useLPLocation';

export const useRates = () => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userSelectors.getUserData)!;
  const rates = useAppSelector(exchangeRatesSelectors.getExchangeRatesData)!;
  const { suggestedCountries } = useLPLocation();
  const [isShowRates, setIsShowRates] = useState(false);

  const userCurrency = useMemo(() => {
    const item = suggestedCountries.find(
      (country) => country.name === userData?.country,
    );
    return (item?.currency ?? BASE_CURRENCY) as CurrencyType;
  }, [userData?.country]);

  const userCurrencyRate = useMemo(() => {
    if (!rates) return '1';

    return formatToNumber(rates[userCurrency] ?? 1);
  }, [rates]);

  useEffect(() => {
    if (!rates && isShowRates) {
      dispatch(fetchExchangeRates());
    }
  }, [isShowRates]);

  useEffect(() => {
    const userFromAcceptableCountries = () =>
      suggestedCountries.some((item) => item.name === userData?.country);

    if (
      userFromAcceptableCountries() &&
      userData?.country !== Countries.UnitedStates
    ) {
      setIsShowRates(true);
    } else {
      setIsShowRates(false);
    }
  }, [userData?.country]);

  const toUserCurrency = useCallback(
    (amount: number) => {
      if (!rates) return '';

      const convertedAmount = convertCurrencyTo(rates)({
        amount,
        to: userCurrency,
      });

      return formatToNumber(convertedAmount);
    },
    [rates],
  );

  const fromUserCurrencyToBase = useCallback(
    (amount: number, currency: CurrencyType) => {
      if (!rates) return '';

      const convertedAmount = convertCurrencyTo(rates)({
        amount,
        from: currency,
        to: BASE_CURRENCY,
        withCeil: false,
      });

      return convertedAmount.toFixed(4);
    },
    [rates],
  );

  return {
    isShowRates,
    rates,
    userCurrency,
    userCurrencyRate,
    toUserCurrency,
    fromUserCurrencyToBase,
  };
};
