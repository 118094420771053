import { useMemo } from 'react';
import { useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { INavigationStep } from '../types';
import {
  APP_ROUTES,
  IntermediateScreen,
  KnowledgeQuizStatus,
  TEST_TASK_STATUS,
  TestTaskType,
} from '../constants';
import { useUserTestTasks } from './useUserTestTasks';
import { userRouteSelector } from '../redux/userRoute';
import { useUserVideoInterview } from './useUserVideoInterview';
import { ReactComponent as VideoCameraIcon } from '../assets/video-camera-icon.svg';
import { useKnowledgeQuiz } from './useKnowledgeQuiz';
import { useIsIndependentVIPage } from './useIsIndependentVIPage';
import { useUserMeetings } from './useUserMeetings';
import { isPast } from 'date-fns';

const QuestioneerStepperPages = [
  APP_ROUTES.EMAIL,
  APP_ROUTES.NAME,
  APP_ROUTES.COUNTRY,
  APP_ROUTES.CITY,
  APP_ROUTES.OFFICE,
  APP_ROUTES.ENGLISH_LEVEL,
  APP_ROUTES.SPECIALIZATION,
  APP_ROUTES.TECH_SKILLS,
  APP_ROUTES.PRIMARY_SKILLS,
  APP_ROUTES.SECONDARY_SKILLS,
  APP_ROUTES.EXPERIENCE,
  APP_ROUTES.SENIORITY,
  APP_ROUTES.ENGAGEMENT,
  APP_ROUTES.COMPENSATION,
  APP_ROUTES.NOTICE_PERIOD,
  APP_ROUTES.PROFESSIONAL_IDENTITY,
  APP_ROUTES.PROFILE_REVIEW,
  APP_ROUTES.ABOUT_YOURSELF,
];

const VideoInterviewPages = [
  APP_ROUTES.VIDEO_INTERVIEW_SELECTION,
  APP_ROUTES.VIDEO_INTERVIEW_FINISHED,
  APP_ROUTES.RECORD_VIDEO,
  APP_ROUTES.CANDIDATE_VIDEO_INTERVIEW_START,
  APP_ROUTES.CANDIDATE_VIDEO_INTERVIEW_RECORD,
];

const KnowledgeQuizPages = [APP_ROUTES.KNOWLEDGE_QUIZ];

const TestTaskPages = [
  APP_ROUTES.TEST_TASK_SELECTION,
  APP_ROUTES.QUIZ_TEST_TASK,
  APP_ROUTES.CODILITY_TEST_TASK,
  APP_ROUTES.INTERNAL_TEST_TASK,
  APP_ROUTES.INTERNAL_TEST_TASK_SENT,
];

const IntroCallPages = [APP_ROUTES.APPLICATION_SUCCESS];

const TechnicalInterviewPages = [APP_ROUTES.TECHNICAL_INTERVIEW];

export const useMainNavigationSteps = () => {
  const userData = useAppSelector(userSelectors.getUserData);
  const stepperRoute = useAppSelector(userRouteSelector.getUserRoute);

  const { knowledgeQuiz } = useKnowledgeQuiz();
  const isIndependentVIPage = useIsIndependentVIPage();
  const {
    availableVideoInterview,
    userVideoInterview,
    isUserQuitedVideoInterview,
    isUserSkippedVideoInterview,
  } = useUserVideoInterview();
  const { availableTestTasks } = useUserTestTasks();
  const { activeIntroCall, activeTechnicalInterview } = useUserMeetings();

  const internalTestTask = userData?.testTasks?.find(
    (testTask) => testTask.testTaskType === TestTaskType.INTERNAL,
  );

  const isQuestioneerPage = QuestioneerStepperPages.includes(stepperRoute);
  const isVIPage = VideoInterviewPages.includes(stepperRoute);
  const isKnowledgeQuizPage = KnowledgeQuizPages.includes(stepperRoute);
  const isTestTaskPage = TestTaskPages.includes(stepperRoute);
  const isIntroCallPage = IntroCallPages.includes(stepperRoute);
  const isTICallPage = TechnicalInterviewPages.includes(stepperRoute);

  const mainNavigationSteps = useMemo(() => {
    let numeration = 1;
    const steps: INavigationStep[] = [];

    steps.push({
      label: 'Tell us about yourself',
      numeration: numeration,
      isActive: isQuestioneerPage,
      isCompleted: !!userData?.profileSubmittedAt,
      isNavigatable: !!userData?.profileSubmittedAt,
      route: APP_ROUTES.ABOUT_YOURSELF,
    });
    numeration++;

    if (userData?.intermediateScreen === IntermediateScreen.FirstStepIsDone) {
      steps.push({
        label: 'Proceed with next steps',
        numeration: numeration,
        isActive: true,
        isCompleted: false,
        isNavigatable: false,
      });

      return steps;
    }

    if (knowledgeQuiz) {
      steps.push({
        label: 'Take a 5 min knowledge quiz',
        numeration: numeration,
        isActive: isKnowledgeQuizPage,
        isCompleted: [
          KnowledgeQuizStatus.Passed,
          KnowledgeQuizStatus.Failed,
        ].includes(knowledgeQuiz.status),
        route: isKnowledgeQuizPage ? stepperRoute : undefined,
      });
      numeration++;
    }

    if (!internalTestTask && availableTestTasks.length) {
      steps.push({
        label: 'Pass a short test',
        numeration: numeration,
        isActive: isTestTaskPage,
        isCompleted: availableTestTasks.some((tt) =>
          [
            TEST_TASK_STATUS.DONE,
            TEST_TASK_STATUS.FAILED,
            TEST_TASK_STATUS.PASSED,
          ].includes(tt.status),
        ),
        route: isTestTaskPage ? stepperRoute : undefined,
      });
      numeration++;
    }

    if (
      availableVideoInterview?.length ||
      userVideoInterview ||
      isIndependentVIPage
    ) {
      const isVISkipped = isUserSkippedVideoInterview;
      const isVIQuited = isUserQuitedVideoInterview;

      const viStep = {
        label: 'Record a short video',
        icon: VideoCameraIcon,
        numeration: numeration,
        isActive: isVIPage,
        isNavigatable: !isVIPage && (isVIQuited || isVISkipped),
        isCompleted: userVideoInterview && !(isVISkipped || isVIQuited),
        isSkipped: !isVIPage && (isVISkipped || isVIQuited),
        route: isVIPage
          ? stepperRoute
          : isVISkipped
          ? APP_ROUTES.VIDEO_INTERVIEW_SELECTION
          : APP_ROUTES.VIDEO_INTERVIEW_FINISHED,
      };

      if (isIndependentVIPage) {
        return [
          {
            ...viStep,
            numeration: 1,
          },
        ];
      }

      steps.push(viStep);
      numeration++;
    }

    steps.push({
      label: 'Talk with our talent executive',
      numeration: numeration,
      isActive: isIntroCallPage && !userData?.closedAt,
      isCompleted:
        !!activeIntroCall &&
        !activeIntroCall.canceled &&
        isPast(new Date(activeIntroCall.scheduledDate)),
    });
    numeration++;

    if (internalTestTask) {
      steps.push({
        label: 'Pass a short test',
        numeration: numeration,
        isActive: isTestTaskPage,
        isCompleted: internalTestTask.status.some((status) =>
          [
            TEST_TASK_STATUS.DONE,
            TEST_TASK_STATUS.FAILED,
            TEST_TASK_STATUS.PASSED,
          ].includes(status),
        ),
        route: isTestTaskPage ? stepperRoute : undefined,
      });
      numeration++;
    }

    steps.push({
      label: 'Ace the interview with our manager',
      numeration: numeration,
      isActive: isTICallPage,
      isCompleted:
        !!activeTechnicalInterview &&
        !activeTechnicalInterview.canceled &&
        isPast(new Date(activeTechnicalInterview.scheduledDate)),
    });
    numeration++;

    steps.push({
      label: 'Get an offer',
      numeration: numeration,
    });
    return steps;
  }, [userData, availableTestTasks, activeIntroCall, activeTechnicalInterview]);

  return {
    mainNavigationSteps,
  };
};
