import React, { useEffect } from 'react';
import { useAppSelector } from 'src/redux';
import { Box, Link, Typography } from '@mui/material';
import { Navigate, Link as RouterLink } from 'react-router-dom';
import { BrandLogo } from '../../components';
import { ExpiredInvitationLink } from './components/ExpiredInvitationLink';
import { Country, TopClients } from './components/EmailIntroPage';
import { useSignInHooks } from './hooks/useSignInHooks';
import { APP_ROUTES, SIGN_UP_PROVIDERS } from '../../constants';
import { positionsSelectors } from 'src/redux/positions';
import { logEvent } from 'src/services';

export const SignIn: React.FC = () => {
  const {
    isExpiredMagicLink,
    showWelcomePageTopClients,
    showWelcomeCountrySpecificTitle,
  } = useSignInHooks();
  const position = useAppSelector(positionsSelectors.getPositionData);
  const isIndeedApplicant = position?.utmProvider === SIGN_UP_PROVIDERS.INDEED;

  useEffect(() => {
    logEvent('launchpod - welcome-page-v1_loaded');

    if (showWelcomePageTopClients && !isIndeedApplicant) {
      logEvent('launchpod - welcome-page-v2_top-clients-variant');
    }
    if (showWelcomeCountrySpecificTitle && !isIndeedApplicant) {
      logEvent('launchpod - welcome-page-v2_country-specific-title-variant');
    }
  }, []);

  const handlePrivacyPolicyClick = () => {
    logEvent('launchpod - welcome-page-v1_privacy-policy-clicked');
  };

  const renderMainContent = () => {
    if (isIndeedApplicant) {
      return <Navigate to={`/${APP_ROUTES.EMAIL}`} />;
    }
    if (isExpiredMagicLink) {
      return <ExpiredInvitationLink />;
    }
    if (showWelcomePageTopClients) {
      return <TopClients />;
    }
    if (showWelcomeCountrySpecificTitle) {
      return <Country />;
    }
    return <Navigate to={`/${APP_ROUTES.EMAIL}`} />;
  };

  return (
    <Box
      sx={(theme) => ({
        width: '100vw',
        height: '100vh',
        display: 'grid',
        gridTemplate: 'repeat(3, auto) / repeat(2, auto)',
        background: theme.palette.background.backgroundPrimary,
        overflowY: 'auto',

        [theme.breakpoints.down('md')]: {
          gridTemplate: 'repeat(3, auto) / repeat(2, auto)',
          justifyItems: 'center',
          gap: '1.25rem 0',
        },

        [theme.breakpoints.down('sm')]: {
          overflowY: 'auto',
          background: 'white',
          px: 2,
          gridTemplate: 'repeat(6, auto) / repeat(4, 1fr)',
          gap: '0',
          justifyItems: 'revert',
        },
      })}
    >
      <Box
        data-testid="sign-in-brand-logo"
        sx={(theme) => ({
          gridColumn: 1,
          gridRow: 1,
          mt: '2rem',
          ml: '1.75rem',

          [theme.breakpoints.down('md')]: {
            gridColumn: 1,
            gridRow: 1,
            justifySelf: 'auto',
            mt: '4.5rem',
            ml: '5.5rem',
          },
          [theme.breakpoints.down('sm')]: {
            gridColumn: '1/3',
            gridRow: 1,
            mt: 3.25,
            ml: 0,
            alignSelf: 'center',
            justifySelf: 'left',
          },
        })}
      >
        <BrandLogo width={94} />
      </Box>

      {renderMainContent()}

      <Box
        sx={(theme) => ({
          alignSelf: 'center',
          gridRow: 3,
          gridColumn: '1/-1',
          pb: 2,
          display: 'flex',
          justifyContent: 'center',
          mt: 1,

          [theme.breakpoints.down('sm')]: {
            gridRow: 6,
            justifySelf: 'center',
            pt: '1.875rem',
            pb: '1.875rem',
            gridColumn: '1/auto',
          },
        })}
      >
        <Typography
          noWrap
          variant="body2"
          color="secondary"
          textAlign="center"
          fontSize="0.675rem"
          mx={2}
        >
          <Link component={RouterLink} to="/terms-of-use">
            Terms of use
          </Link>
        </Typography>
        <Typography
          noWrap
          variant="body2"
          color="secondary"
          textAlign="center"
          fontSize="0.675rem"
          mx={2}
        >
          <Link
            href="https://agileengine.com/privacy-policy"
            target="_blank"
            onClick={handlePrivacyPolicyClick}
          >
            Privacy policy
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
