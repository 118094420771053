import React, { useEffect, useState } from 'react';
import NonTechVideoInterviewSelection from './components/NonTechVideoInterviewSelection/NonTechVideoInterviewSelection';
import TechVideoInterviewSelection from './components/TechVideoInterviewSelection/TechVideoInterviewSelection';
import { useIsIndependentVIPage, useUserVideoInterview } from 'src/hooks';
import { useAppDispatch, useAppSelector } from '../../redux';
import { userSelectors } from '../../redux/user';
import {
  restartVideoInterview,
  skipVideoInterview,
  startVideoInterview,
} from '../../redux/videoInterview';
import { APP_ROUTES, WORK_AREA_TYPE } from '../../constants';
import { useNavigate } from 'react-router';
import { logEvent } from 'src/services';
import { userRouteSelector } from '../../redux/userRoute';
import { UserVideoInterviewStatus } from 'src/types/userVideoInterviewData';
import { MatchingVIPosition } from 'src/types/videoInterviewData';

const VideoInterviewSelection: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    specializationType,
    userVideoInterview,
    availableVideoInterview,
    isUserSkippedVideoInterview,
  } = useUserVideoInterview();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const userReminderDate = userData.videoInterviewReminderDate;
  const isIndependentVIPage = useIsIndependentVIPage();

  const [showVIDialog, setShowVIDialog] = useState(false);
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [showRescheduleMessage, setShowRescheduleMessage] = useState(
    Boolean(userReminderDate),
  );

  const [selectedVI, setSelectedVI] = useState<MatchingVIPosition | null>(null);

  const stepperRoute = useAppSelector(userRouteSelector.getUserRoute)!;

  useEffect(() => {
    logEvent('launchpod - video-interview-selection-page_loaded');
  }, []);

  useEffect(() => {
    if (availableVideoInterview?.length === 1 && !userVideoInterview) {
      setSelectedVI(availableVideoInterview[0]);
    }
  }, [availableVideoInterview]);

  useEffect(() => {
    if (
      userVideoInterview &&
      isIndependentVIPage &&
      ![
        UserVideoInterviewStatus.TODO,
        UserVideoInterviewStatus.SKIPPED,
      ].includes(userVideoInterview.status)
    ) {
      setTimeout(
        () => navigate(APP_ROUTES.CANDIDATE_VIDEO_INTERVIEW_RECORD),
        0,
      );
    }
  }, [userVideoInterview, isIndependentVIPage]);

  const handleAddToCalendar = () => {
    setShowVIDialog(false);
    setShowScheduleDialog(true);
  };

  const handleCloseScheduleForLater = () => {
    setShowScheduleDialog(false);
  };

  const handleStartVideoInterviewNow = () => {
    setShowScheduleDialog(false);
    setShowVIDialog(true);
  };

  const handleScheduleSuccess = () => {
    setShowRescheduleMessage(true);
    setShowScheduleDialog(false);
  };

  const handleStartRecord = (e: React.SyntheticEvent | null): void => {
    if (e) {
      e.preventDefault();
    }

    logEvent('launchpod - video-interview-selection-page_start-interview');

    if (userVideoInterview) {
      dispatch(restartVideoInterview(userVideoInterview.id));
      setShowVIDialog(false);
    } else if (selectedVI?.id) {
      dispatch(
        startVideoInterview({
          videoInterviewId: selectedVI?.id,
        }),
      );
      setShowVIDialog(false);
    } else {
      navigate(1);
    }
  };

  const handleSelectVideoInterview = (value: MatchingVIPosition) => {
    const targettedVI = availableVideoInterview?.find(
      (item) => item.id === value.id,
    );

    if (targettedVI) {
      setSelectedVI(targettedVI);
    }
  };

  const handleSkipVideoInterview = (e: React.SyntheticEvent | null): void => {
    if (e) {
      e.preventDefault();
    }

    logEvent('launchpod - video-interview-selection-page_skip-click');

    setShowVIDialog(false);
    if (!!userVideoInterview) {
      navigate(`/${stepperRoute}`);
    } else {
      dispatch(skipVideoInterview());
    }
  };

  const selectionProps = {
    handleCloseScheduleForLater,
    handleScheduleSuccess,
    handleStartRecord,
    handleAddToCalendar,
    handleStartVideoInterviewNow,
    handleSkipVideoInterview,
    showScheduleDialog,
    showRescheduleMessage,
    showVIDialog,
    setShowVIDialog,
    isIndependentVIPage,
    availableVideoInterview,
    handleSelectVideoInterview,
    isUserSkippedVideoInterview,
    selectedVI: selectedVI,
  };

  if (specializationType === WORK_AREA_TYPE.NON_ENGINEERING) {
    return <NonTechVideoInterviewSelection {...selectionProps} />;
  }

  if (specializationType === WORK_AREA_TYPE.ENGINEERING) {
    return <TechVideoInterviewSelection {...selectionProps} />;
  }

  return null;
};

export default VideoInterviewSelection;
