import React from 'react';
import { PageTransitionWrapper } from '../../../../components';
import { Box, Stack, Typography } from '@mui/material';
import SelectionButtons from '../SelectionButtons';
import { ScheduleDialog } from '../../../../components';
import { VideoInterviewDialog } from '../VideoInterviewDialog';
import { useIsLaptop } from 'src/hooks';
import { apiEndpoints } from '../../../../constants';
import VideoInterviewMobileDialog from '../VideoInterviewMobileDialog';
import SelectionVideoPlayer from '../SelectionVideoPlayer';
import { GuaranteeDescription } from '../GuaranteeDescription';
import { MatchingVIPosition } from 'src/types/videoInterviewData';
import { VideoInterviewSelectableList } from '../VideoInterviewSelectableList/VideoInterviewSelectableList';

interface IProps {
  handleCloseScheduleForLater: () => void;
  handleScheduleSuccess: () => void;
  handleStartRecord: (e: React.SyntheticEvent | null) => void;
  handleAddToCalendar: () => void;
  handleStartVideoInterviewNow: () => void;
  showScheduleDialog: boolean;
  showRescheduleMessage: boolean;
  handleSkipVideoInterview: (e: React.SyntheticEvent | null) => void;
  showVIDialog: boolean;
  setShowVIDialog: (showVIDialog: boolean) => void;
  isIndependentVIPage: boolean;
  availableVideoInterview: MatchingVIPosition[] | null;
  handleSelectVideoInterview: (value: MatchingVIPosition) => void;
  isUserSkippedVideoInterview: boolean;
  selectedVI: MatchingVIPosition | null;
}

const TechVideoInterviewSelection: React.FC<IProps> = ({
  handleCloseScheduleForLater,
  handleScheduleSuccess,
  handleStartRecord,
  handleAddToCalendar,
  handleStartVideoInterviewNow,
  showScheduleDialog,
  showRescheduleMessage,
  handleSkipVideoInterview,
  showVIDialog,
  setShowVIDialog,
  isIndependentVIPage,
  availableVideoInterview,
  handleSelectVideoInterview,
  selectedVI,
}) => {
  const isLaptop = useIsLaptop();
  const isMatchedPositionsVI =
    availableVideoInterview && availableVideoInterview?.length >= 1;

  const hasOptionalVI = availableVideoInterview?.some((vi) => !vi.isRequired);

  const VideoComponent = () => {
    return (
      <SelectionVideoPlayer
        videoUrl={apiEndpoints.videoFileUrl('intro-video-engineering.mp4')}
        subtitleUrl={`${process.env.PUBLIC_URL}/intro-video-engineering.vtt`}
        posterUrl={`${process.env.PUBLIC_URL}/intro-video-engineering-poster.jpg`}
        signature="Meet Alex Gorlatov, VP of Engineering at AgileEngine"
      />
    );
  };

  return (
    <>
      {showScheduleDialog && (
        <ScheduleDialog
          isOpen={showScheduleDialog}
          handleClose={handleCloseScheduleForLater}
          handleScheduleSuccess={handleScheduleSuccess}
          isVideoInterview={true}
        />
      )}
      {showVIDialog && (
        <>
          {isLaptop ? (
            <VideoInterviewDialog
              isOpen={showVIDialog}
              handleClose={() => setShowVIDialog(false)}
              handleStartRecord={handleStartRecord}
              handleSkipSelection={handleSkipVideoInterview}
              handleCalendar={handleAddToCalendar}
              showSkipButton={!isIndependentVIPage && hasOptionalVI}
              selectedVI={selectedVI}
            />
          ) : (
            <VideoInterviewMobileDialog
              isOpen={showVIDialog}
              handleClose={() => setShowVIDialog(false)}
            />
          )}
        </>
      )}
      <PageTransitionWrapper>
        <Box data-testid="tech-video-interview-selection-layout">
          <Stack
            sx={(theme) => ({
              [theme.breakpoints.down('lg')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('lg')]: {
                flexDirection: 'row',
              },
            })}
          >
            <Stack
              sx={(theme) => ({
                [theme.breakpoints.up('lg')]: {
                  minWidth: '32rem',
                  maxWidth: '37rem',
                },
              })}
            >
              <Typography variant="h2" mb={2}>
                Record a short video introduction to move faster with your
                application
              </Typography>
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.up('lg')]: {
                    display: 'none',
                  },
                })}
              >
                {VideoComponent()}
              </Box>
              <GuaranteeDescription />
              {isMatchedPositionsVI && (
                <VideoInterviewSelectableList
                  availableVideoInterview={availableVideoInterview}
                  handleSelectVideoInterview={handleSelectVideoInterview}
                  selectedVIPosition={selectedVI?.id || null}
                />
              )}

              {!isMatchedPositionsVI && (
                <Typography variant="body1" marginTop="1rem">
                  We’re excited to get to know you better and sooner!
                </Typography>
              )}

              <SelectionButtons
                handleStartVideoInterview={handleStartVideoInterviewNow}
                handleSkipSelection={handleSkipVideoInterview}
                handleSubmitStep={() => {
                  setShowVIDialog(true);
                }}
                handleCalendar={handleAddToCalendar}
                showRescheduleMessage={showRescheduleMessage}
                showSkipButton={!isIndependentVIPage && hasOptionalVI}
              />
            </Stack>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down('lg')]: {
                  display: 'none',
                },
                [theme.breakpoints.up('xl')]: {
                  marginLeft: '-1rem',
                },
                marginLeft: '-3rem',
                marginTop: '4.3rem',
              })}
            >
              {VideoComponent()}
            </Box>
          </Stack>
        </Box>
      </PageTransitionWrapper>
    </>
  );
};

export default TechVideoInterviewSelection;
