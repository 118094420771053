import React from 'react';
import { Typography } from '@mui/material';
import { ResponsiveBreak } from '../../../../../components';

const NotSupportedLegalRestrictionsMessage: React.FC = () => {
  return (
    <>
      <Typography variant="body1" data-testid="success-text">
        Due to legal reasons, we can proceed only if a candidates' notice period
        is 60 days or less.
      </Typography>
      <Typography variant="body1">
        If the situation changes, we would be happy{' '}
        <ResponsiveBreak breakpoint="sm" />
        to reconnect.
      </Typography>
    </>
  );
};

export default NotSupportedLegalRestrictionsMessage;
