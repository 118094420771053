import React, { useCallback } from 'react';
import { BoxProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { ContentWrapper } from '../ContentWrapper';
import { AuthUserConfirmation } from '../../../../components';
import { EditOrResendEmailBlock } from '../../../../features/profileReview/components';
import { usePersistUserAnswers } from '../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../redux';
import {
  authSelectors,
  authUser,
  setAuthUserEmailSent,
} from '../../../../redux/auth';
import { magicLinkCandidateSelectors } from 'src/redux/magicLinkCandidate';
import { positionsSelectors } from 'src/redux/positions';
import { massCampaignCandidateSelectors } from 'src/redux/massCampaignCandidate';
import { updateEmail, userSelectors } from '../../../../redux/user';
import { isStatusLoading } from '../../../../utils';
import { candidateSourceSelectors } from 'src/redux/candidateSource';

interface IProps extends BoxProps {
  sx?: (theme: Theme) => SystemStyleObject<Theme>;
  'data-testid'?: string;
}

export const EmailIntroWrapper: React.FC<IProps> = ({
  children,
  sx,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userSelectors.getUserData);
  const zohoId = useAppSelector(
    magicLinkCandidateSelectors.getMagicLinkCandidateZohoId,
  );
  const position = useAppSelector(positionsSelectors.getPositionData);
  const massCampaignData = useAppSelector(
    massCampaignCandidateSelectors.getMassCampaignCandidateData,
  );
  const candidateSource = useAppSelector(
    candidateSourceSelectors.getCandidateSourceData,
  );
  const isEmailLoading = useAppSelector(
    userSelectors.getIsUserPerformingAction,
  );
  const authApiStatus = useAppSelector(authSelectors.getAuthApiStatus);
  const isAuthUserEmailSent = useAppSelector(
    authSelectors.getIsAuthUserEmailSent,
  );

  const [savedEmail] =
    usePersistUserAnswers<Record<string, string>>('emailStep');

  const sendEmailAction = useCallback(
    async (email: string) => {
      if (userData) {
        return await dispatch(updateEmail({ email })).unwrap();
      } else {
        return await dispatch(
          authUser({
            email,
            zohoId,
            position,
            massCampaignData,
            candidateSource,
          }),
        ).unwrap();
      }
    },
    [userData],
  );

  return (
    <ContentWrapper
      data-testid={props['data-testid']}
      sx={(theme) => ({
        gridTemplate: `repeat(1, auto) / repeat(0, auto)`,
        height: 'auto',
        maxHeight: '100%',
        py: '3rem',
        px: '3rem',
        [theme.breakpoints.down('xl')]: {
          gridRow: 2,
          gridTemplate: `repeat(3, min-content) / repeat(1, 1fr)`,
          width: '90%',
        },
        [theme.breakpoints.down('md')]: {
          py: '2rem',
          px: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          py: '0.5rem',
          px: '0.5rem',
          borderRadius: 0,
        },
        alignItems: 'center',
        textAlign: 'left',
        ...(sx && sx(theme)),
      })}
    >
      {isAuthUserEmailSent ? (
        <>
          <AuthUserConfirmation email={savedEmail?.email} />
          <EditOrResendEmailBlock
            userEmail={savedEmail?.email}
            sendEmailAction={sendEmailAction}
            emailChangeAction={() => dispatch(setAuthUserEmailSent(false))}
            isLoading={isStatusLoading(authApiStatus) || isEmailLoading}
          />
        </>
      ) : (
        children
      )}
    </ContentWrapper>
  );
};
