import React from 'react';
import { BoxProps, Theme } from '@mui/material';
import { ANIMATION_DELAY, ANIMATION_DURATION } from '../../../constants';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { AnimatedBox } from '../../../components';
import { motion } from 'framer-motion';

interface IProps extends BoxProps {
  sx?: (theme: Theme) => SystemStyleObject<Theme>;
  'data-testid'?: string;
}

export const ContentWrapper: React.FC<IProps> = ({
  children,
  sx,
  ...props
}) => {
  return (
    <AnimatedBox
      sx={(theme) => ({
        flex: 1,
        height: '86vh',
        width: '100%',
        maxWidth: '59.5rem',
        maxHeight: '36.25rem',
        borderRadius: '48px',
        background: 'white',
        display: 'grid',
        gridRow: '1/3',
        gridColumn: '1/-1',
        alignSelf: 'center',
        justifySelf: 'center',
        px: 12,
        mt: 2.5,
        ...(sx && sx(theme)),
      })}
      component={motion.div}
      initial={{ y: '-20%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: ANIMATION_DURATION - ANIMATION_DELAY,
        delay: ANIMATION_DELAY,
      }}
      {...props}
    >
      {children}
    </AnimatedBox>
  );
};
